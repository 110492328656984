import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogPost.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A moins de vivre dans une grotte, vous en avez sûrement entendu parler du dernier rapport GIEC, publié le 6 août dernier.`}</p>
    <p><strong parentName="p">{`Mais qu’est ce que le GIEC ?`}</strong>{` `}</p>
    <p>{`Le GIEC (groupe d’experts intergouvernemental sur l’évolution du climat) est composé de 195 états membres, à la demande du G7.`}</p>
    <p>{`Il a été créé en 1988 par le Programme des Nations unies (PNUE) et l’organisation météorologique mondiale (OMM). `}</p>
    <p>{`C'est un organisme scientifique et non politique. Cela veut dire qu’aucune décision n’est prise. C’est important de le souligner, car ce rapport va informer les décideurs politiques, mais cela ne veut pas dire que les solutions préconisées vont être appliquées. `}</p>
    <p>{`Le dernier rapport du GIEC, fruit de 6 ans de travail !`}</p>
    <p>{`Sachez que ce rapport est le fruit de longues années de recherches, soit 6 ans à aboutir. `}</p>
    <p>{`Pourquoi? Ce rapport est fait par des centaines d’experts scientifiques et chaque version est relue par les meilleurs experts du climat du monde. Mis bout à bout, ce dernier a nécessité 8 mois de temps bénévole à temps plein. `}</p>
    <p>{`Bref, le rapport GIEC, c'est la Rolls Royce des synthèses et des évaluations faites par les groupes scientifiques du monde entier. `}</p>
    <p><strong parentName="p">{`Comment le GIEC fonctionne-t-il ?`}</strong>{` `}</p>
    <p>{`Le GIEC fonctionne en trois groupes de travail :`}</p>
    <ol>
      <li parentName="ol">{`Le premier groupe étudie les aspects scientifiques du changement climatique - c’est le rapport publié aujourd’hui`}</li>
      <li parentName="ol">{`Le deuxième étudie les conséquences, la vulnérabilité et l'adaptation - celui-ci sera publié dans quelques mois`}</li>
      <li parentName="ol">{`Le troisième étudie l'atténuation du changement climatique par des solutions et possibilités `}</li>
    </ol>
    <p><strong parentName="p">{`En quelques phrases, que dit le rapport GIEC ?`}</strong></p>
    <p>{`Le dernier rapport souligne l'importance de la responsabilité humaine dans le changement climatique. Par exemple, selon le journal Le Monde, 7 millions d’hectares de forêts ont été perdus entre 2000 et 2010.`}</p>
    <p>{`Il propose aussi des rapports scientifiques pour trouver des solutions afin de lutter contre le changement climatique. En effet, le dérèglement climatique touche toutes les régions du monde et à un rythme qui s’intensifie. Oui, même Nauru, l’une des plus petites îles du monde est touchée. `}</p>
    <p>{`Comme vous avez pu vous en rendre compte, l’été 2021 a été marqué par une série de catastrophes naturelles de plus en plus récurrentes, comme les incendies qui ont ravagé la Grèce et la Turquie début août et la famine qui a touché l’île de Madagascar fin juin à cause d’importantes sécheresses. Il n’y a pas de doute soulevé par le GIEC : toutes ces catastrophes sont directement ou indirectement liées à l’activité humaine.`}</p>
    <p><strong parentName="p">{`Agir pour la planète`}</strong></p>
    <p>{`Selon le rapport du GIEC de août 2022, deux urgences climatiques ne sont plus à négliger.`}</p>
    <ul>
      <li parentName="ul">{`D’une part, maintenir le réchauffement climatique à 1,5°C contrairement aux scénarios qui annoncent une augmentation de 5°C à partir de 2030.`}</li>
      <li parentName="ul">{`D’autre part, limiter la hausse du niveau des océans qui est de 20 cm plus élevé qu’il y a un siècle et qui pourrait atteindre 2 mètres dans les prochaines décennies. (Avant de voir les villes de New York et Shanghai disparaîtraient sous les eaux !)`}</li>
    </ul>
    <p><strong parentName="p">{`Et à notre échelle, ça donne quoi ?`}</strong>{` `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Économiser notre énergie en réduisant les émissions de gaz à effet de serre pour atteindre la neutrocarbonalité en 2050`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Éliminer la production de charbon d’ici 2030`}</li>
          <li parentName="ul">{`Supprimer la production d’énergies fossiles d’ici 2040`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Changer ses habitudes de consommation`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Favoriser le réemploi (emballage réutilisable :) , upcycling, achat d’occasion) `}</li>
          <li parentName="ul">{`Se nourrir de manière responsable : acheter des produits locaux et privilégier le vrac `}</li>
          <li parentName="ul">{`Trier ses déchets (trop facile !)`}</li>
        </ul>
      </li>
    </ul>
    <p><strong parentName="p">{`La bonne nouvelle, c’est qu’il est encore temps d’agir !`}</strong></p>
    <p>{`Vous souhaitez en savoir plus, regardez cette `}<a parentName="p" {...{
        "href": "https://www.instagram.com/tv/CSXDMpCHzUH/?utm_source=ig_web_copy_link"
      }}>{`vidéo`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      