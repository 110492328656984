import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import "./BlogPost.scss"
import { MDXProvider } from "@mdx-js/react"
import { CMS_SHORTCODES } from "../components/RenderMarkdown"

interface IBlogPost {
  pageContext: {
    frontmatter: {
      date: string
      title: string
      category: string[]
    }
  }
}

const BlogPost: React.FunctionComponent<IBlogPost> = props => {
  return (
    <Layout activePage="blog">
      <SEO
        title={props.pageContext.frontmatter.title}
        description="La Consigne GreenGo est une app' mobile permettant d’emprunter facilement des emballages réutilisables et consignés dans tout type de structures : restaurants, cafétérias d’entreprises et grande distribution."
        keywords={["consigne", "emballage", "zéro", "déchets", "restauration"]}
      />
      <div className="blog-post section-padding">
        <div className="container">
          <Link
            to="/blog"
            className="link button button--color-pink link--back-to-blog--top"
          >
            {"<"}
          </Link>
          <h1 className="heading heading--blog--1 heading--svg-underline">
            {props.pageContext.frontmatter.title}
          </h1>
          <div className="markdown">
            <MDXProvider components={{...CMS_SHORTCODES}}>{props.children}</MDXProvider>
          </div>
          <Link
            to="/blog"
            className="link button button--color-pink link--back-to-blog--bottom"
          >
            Retour au blog
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost
